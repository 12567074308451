<template>
  <page>
    <div class="home-page">
      <zui-header :hasLine="true" />
      <div class="service" style="padding: 0; margin-top: 77px">
        <div class="frist-m3-area">
          <div class="frist-m3-area frist-m3-area-top">
            <img class="banner-img" src="https://www.achencybe.com/img/pc/the4/4-1-1.jpg" />
            <div class="frist-m3-area-sub-title">
              <div style="margin-bottom: 6px">
                <span>{{$t('car_list.the4.suitable')}}</span>
              </div>
              <div><span>{{$t('car_list.the4.style')}}</span></div>
            </div>
            <div class="frist-m3-area-title">
              <div><span>{{$t('car_list.the4.slogan')}}</span></div>
            </div>
            <div class="frist-m3-area-buy-area">
              <div class="frist-m3-area-buy-button" @click="goToBuy()">
                {{$t('car_list.buy_now')}}
              </div>
            </div>
          </div>
          <div
            class="frist-m3-area frist-m3-area-show"
            style="padding-top: 0; background-color: #0c0c0c"
          >
            <img
                class="frist-m3-area-top-bottom-more"
                src="https://www.achencybe.com/img/pc/others/LearnMore.png"
                alt=""
            />
            <div class="frist-m3-area-show-area-div">
              <!--              <img src="../../../assets/images/other/M3_M4_S.png" style="width:80%;margin:110px 0 50px">-->
              <div class="frist-m3-area-show-area-div-top-box">
                <div class="logo-box">
                  <img class="logo" src="https://www.achencybe.com/img/pc/logo.png" />
                  <div class="line"></div>
                </div>
                <div style="text-align: left; font-size: 18px">
                  {{$t('car_list.the4.show_page.technology_1')}}<br />
<!--                  {{$t('car_list.the4.show_page.technology_2')}}<br />-->
                  {{$t('car_list.the4.show_page.technology_3')}}<br />
                  {{$t('car_list.the4.show_page.technology_4')}}
                </div>
              </div>
              <img src="https://www.achencybe.com/img/pc/the4/4-2-1.png" style="width: 60%; margin-bottom: 100px" />
<!--              <img-->
<!--                src="https://www.achencybe.com/img/pc/detail/the5/2-3-2.png"-->
<!--                style="width: 60%; margin-top: 80px; margin-bottom: 100px"-->
<!--              />-->
            </div>
          </div>
          <div class="frist-m3-area frist-m3-area-goodlist">
            <div class="features-box">
              <features
                title="car_list.the4.show_page.technology_5"
                :sub-title="subTitle"
                :isThe3="true"
                :language="this.$i18n.locale"
                :isHidden="false"
                :list="list"
              />
            </div>
            <div
              class="font-size-padding50 font-size-12"
              style="width: 100%; font-weight: 100"
            >
              {{$t('car_list.care_plus_remark_1')}}<br />
            </div>
            <div class="banner-text">
              <div style="font-size: 55px">{{$t('car_list.the4.show_page.slogan')}}</div>
              <div style="font-size: 16px;max-width: 900px;margin:0 auto;">
                &nbsp;
              </div>
              <div style="font-size: 16px;max-width: 900px;margin:0 auto;">
                {{$t('car_list.the4.show_page.sub_slogan')}}
              </div>
            </div>
            <div class="carousel-box">
              <div class="left" @click="$refs.carousel.prev()">
                <img src="https://www.achencybe.com/img/pc/detail/challenger/left.png" />
              </div>
              <el-carousel
                :interval="5000"
                arrow="never"
                :height="bannerHeight"
                ref="carousel"
              >
                <el-carousel-item v-for="item in 5" :key="item">
                  <img
                    :src="'https://www.achencybe.com/img/pc/the4/4-4-' + item + '.jpg'"
                    alt=""
                  />
                </el-carousel-item>
              </el-carousel>
              <div class="right" @click="$refs.carousel.next()">
                <img src="https://www.achencybe.com/img/pc/detail/challenger/right.png" alt="" />
              </div>
            </div>
            <div style="background-color: #0c0c0c; padding-top: 150px">
              <introduce imgRight :introduceList="introduceList" :direction="'right'" />
              <div
                style="
                  max-width: 1480px;
                  width: 100%;
                  margin-top: 200px;
                  margin: 0 auto;
                "
              >
                <div
                  style="
                    font-size: 54px;
                    text-align: left;
                    width: 1200px;
                    margin: 0 auto;
                  "
                >
                  DESIGNED BY ACHEN&CYBE STUDIO
                </div>
                <div
                  style="
                    font-size: 54px;
                    text-align: left;
                    width: 1200px;
                    margin: -18px auto 0;
                  "
                >
                  独有原创设计
                </div>
                <div
                  style="
                    font-size: 14px;
                    text-align: left;
                    width: 1200px;
                    margin: 0 auto;
                  "
                >
                  由Achen&Cybe Studio提供
                </div>
                <img
                  src="https://www.achencybe.com/img/pc/the4/4-6-1.png"
                  class="frist-m3-area-goodlist-image-area"
                />
              </div>

              <detail-footer
                title="BMW THE 4"
                :img="'https://www.achencybe.com/img/pc/the4/4-8-1.png'"
                @buy="goToBuy()"
              />
            </div>
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
      <zui-footer />
    </div>
  </page>
</template>
<script>
import "../../less/home-page.less";
import "../../less/m3-show.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";
import features from "@/components/features";
import introduce from "@/components/introduce";
import DetailFooter from "@/components/detail-footer";

export default {
  components: { Page, ZuiHeader, ZuiFooter, features, introduce, DetailFooter },
  data() {
    return {
      bannerHeight: "1050px",
      list: [],
      subTitle: [
          "car_list.the4.show_page.technology_6",
          "car_list.the4.show_page.technology_7"
      ],
      introduceList: [
        {
          img: "https://www.achencybe.com/img/pc/the4/4-5-1.jpg",
          title: "car_list.the4.show_page.introduces.item1.title",
          content:"car_list.the4.show_page.introduces.item1.content",
          contImg: "",
          subContent: [],
        },
        {
          img: "https://www.achencybe.com/img/pc/the4/4-5-2.jpg",
          title: "car_list.the4.show_page.introduces.item2.title",
          content:"car_list.the4.show_page.introduces.item2.content",
          imgWidth:"650px",
          subContent: ["car_list.the4.show_page.introduces.item2.sub_content.sub_1"],
        },
        {
          img: "https://www.achencybe.com/img/pc/the4/4-5-3.jpg",
          title: "car_list.the4.show_page.introduces.item3.title",
          content:"car_list.the4.show_page.introduces.item3.content",
          contImg: "",
          subContent: [
            "car_list.the4.show_page.introduces.item3.sub_content.sub_1",
            "car_list.the4.show_page.introduces.item3.sub_content.sub_2",
          ],
        },
        {
          img: "https://www.achencybe.com/img/pc/the4/4-5-4.jpg",
          title: "car_list.the4.show_page.introduces.item4.title",
          content:"car_list.the4.show_page.introduces.item4.content",
          contImg: "",
          subContent: [
            "car_list.the4.show_page.introduces.item4.sub_content.sub_1",
          ],
        },
      ],
    };
  },
  mounted() {
    this.height = window.innerHeight + "px";
    this.bannerHeight = (651 / 1280) * (window.innerWidth >= 1200 ? window.innerWidth : 1200) + "px";
    window.addEventListener("resize", () => {
      this.$nextTick(() => {
        this.height = window.innerHeight - 120 + "px";
        this.bannerHeight = (651 / 1280) * (window.innerWidth >= 1200 ? window.innerWidth : 1200) + "px";
      });
    });
    this.getCarDetail();
  },
  methods: {
    goToBuy: function () {
      this.$router.push({
        path: "/pages/public/OrderPage",
        query: { cartype: "carlistTHE4" },
      });
    },
    getCarDetail() {
      this.$api
        .getCarDetail({
          carModelName: "THE 4",
        })
        .then((res) => {
          if (res.code === 0) {
            res.data.listAccessoriesList.sort((a, b) => {
              return a.partsIndex - b.partsIndex;
            });
            console.log(res.data)
            this.list = [
              {
                img: "https://www.achencybe.com/img/pc/the4/4-3-1.jpg",
                title: ["car_list.the4.show_page.items.item1.title"],
                year: "car_list.the4.show_page.items.item1.year",
                content:"car_list.the4.show_page.items.item1.content",
                price: res.data.listAccessoriesList[0].partsPrice,
                price_dollar: res.data.listAccessoriesList[0].partsPriceDollar,
              },
              {
                img: "https://www.achencybe.com/img/pc/the4/4-3-2.jpg",
                title: ["car_list.the4.show_page.items.item2.title"],
                year: "car_list.the4.show_page.items.item2.year",
                content:"car_list.the4.show_page.items.item2.content",
                price: res.data.listAccessoriesList[1].partsPrice,
                price_dollar: res.data.listAccessoriesList[1].partsPriceDollar,
              },
              {
                img: "https://www.achencybe.com/img/pc/the4/4-3-3.jpg",
                title: ["car_list.the4.show_page.items.item3.title"],
                year: "car_list.the4.show_page.items.item3.year",
                content:"car_list.the4.show_page.items.item3.content",

                price: res.data.listAccessoriesList[2].partsPrice,
                price_dollar: res.data.listAccessoriesList[2].partsPriceDollar,
              },
              {
                img: "https://www.achencybe.com/img/pc/the4/4-3-4.jpg",
                title: ["car_list.the4.show_page.items.item4.title"],
                year: "car_list.the4.show_page.items.item4.year",
                content:"car_list.the4.show_page.items.item4.content",

                price: res.data.listAccessoriesList[3].partsPrice,
                price_dollar: res.data.listAccessoriesList[3].partsPriceDollar,
              },
              {
                img: "https://www.achencybe.com/img/pc/the4/4-3-5.jpg",
                title: ["car_list.the4.show_page.items.item5.title"],
                year: "car_list.the4.show_page.items.item5.year",
                content:"car_list.the4.show_page.items.item5.content",
                price: res.data.listAccessoriesList[4].partsPrice,
                price_dollar: res.data.listAccessoriesList[4].partsPriceDollar,
              },
              {
                img: "https://www.achencybe.com/img/pc/the4/4-3-6.jpg",
                title: ["car_list.the4.show_page.items.item6.title"],
                year: "car_list.the4.show_page.items.item6.year",
                content:"car_list.the4.show_page.items.item6.content",

                price: res.data.listAccessoriesList[5].partsPrice,
                price_dollar: res.data.listAccessoriesList[5].partsPriceDollar,
              },
            ];
          }
        })
        .catch((err) => {
          console.log("getCarDetail", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .logo-box{
    justify-content: flex-end !important;
    .logo{
      width:360px !important;
    }
    .line{
      margin:0 100px !important;
    }
  }
</style>
